import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWhatsapp, faFacebook, faLinkedin, faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Layout from '../layouts/index';
import Blog01 from '../images/blog/blog.jpg';
import BlogArticle from '../components/Blog';


function Article({locale, location}) {
  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing" inverted border location={location}>
      <section className="bg-grey">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 offset-lg-1">
              <h5>Alejandro Canseco</h5>
              <p>7 de enero de 2020</p>
              <ul className="list-inline">
                <li className="list-inline-item"><a href="https://www.linkedin.com/company/contratosapp/" target="_blank" className="lead text-dark" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                <li className="list-inline-item ml-2"><a href="mailto:hola@trato.io" className="lead text-dark"><FontAwesomeIcon icon={faEnvelope} /></a></li>
              </ul>
            </div>
            <div className="col-lg-8">
              <h1>¿Puedo firmar un contrato con firma electrónica?</h1>
              <p>La firma autógrafa continúa siendo el medio por excelencia para la firma de documentos. No obstante, utilizar medios físicos llega a generar atrasos en el cierre de un negocio cuando las partes se encuentren en diferentes sitios geográficos, debido a que se necesita imprimir el contrato en papel, firmar un juego y enviarlo por mensajería a la contraparte para su cotejo y firma. De esta manera, cada parte conserva un juego en original. Naturalmente es posible viajar a las oficinas de alguna de las partes (o de sus abogados) para firmar el contrato de forma presencial; sin embargo, esto tiene un costo elevado e innecesario, sobretodo cuando el monto de la transacción es bajo.</p>

              <p>Los medios electrónicos han solucionado este problema al permitir la realización de operaciones comerciales entre personas no presentes utilizando una firma electrónica que, al crearse de conformidad al Código de Comercio y la Ley de Firma Electrónica Avanzada, constituye una fuente válida y cierta de obligaciones. Para que una firma electrónica se considere fiable es necesario que reúna los siguientes requisitos:</p>

              <h5>Escrito por Alejandro Canseco</h5>
              <p>Alejandro is the content marketing manager at Trato. he writes to teach the world about the ways of video monetization. He’s also a visual artist, traveller and dog lover (although she’s starting to warm up to cats).</p>

              <h6 className="font-weight-bold">Compartir artículo en</h6>
              <ul className="list-inline p-0">
                <li className="list-inline-item"><a href="" target="_blank" className="lead text-dark" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} /></a></li>
                <li className="list-inline-item ml-2"><a href="https://www.facebook.com/tratoapp" target="_blank" className="lead text-dark" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li className="list-inline-item ml-2"><a href="https://www.linkedin.com/company/contratosapp/" target="_blank" className="lead text-dark" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                <li className="list-inline-item ml-2"><a href="https://twitter.com/TratoApp" target="_blank" className="lead text-dark" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li className="list-inline-item ml-2"><a href="mailto:hola@trato.io" className="lead text-dark"><FontAwesomeIcon icon={faEnvelope} /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>


  );
}

export default Article;
